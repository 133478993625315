import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/', component: () => import('../views/Login.vue'),
    meta: {
      allowAnonymous: true
    }
  },
  {
    path: '/', 
    component: () => import('../views/MainLayout.vue'),
    children: [
      { path: '/wells',name:"wells", component: () => import('../views/ListeWells.vue') },
      { path: '/productions',name:"productions", component: () => import('../views/ListeProductions.vue') },
      { path: '/dictionnary', name:"dictionnary", component: () => import('../views/Dictionnary.vue') },
      { path: '/dashboard', component: () => import('../views/Dashboard.vue') },
      { path: '/users',  component: () => import('../views/Users.vue') },
      { path: '/auth', component: () => import('../components/NotAuth.vue') },
      { path: '/map', name:'map', component: () => import('../views/WellFailures.vue') },
      { path: '/planning', name:'planning',  component: () => import('../views/Planning.vue') },
      { path: '/tests', name:'tests',  component: () => import('../views/Liste_Test.vue') },
      { path: '/repairs', name:'repairs',  component: () => import('../views/Repairs.vue') },
       { path: '/notifHistory', name:'notifHistory',  component: () => import('../components/NotificationHistory.vue') },
    //   { path: '/apm',  component: () => import('../views/Liste_Test_APM.vue') },
    //  { path: '/xtree',  component: () => import('../views/Liste_Test_Xtree.vue') },
    //  { path: '/wellhead', component: () => import('../views/Liste_Test_Wellhead.vue') },
    //  { path: '/annulibo',  component: () => import('../views/Liste_Test_BO.vue') },
    //  { path: '/downhole',  component: () => import('../views/Liste_Test_Downhole.vue') },
      { path: '/about',  component: () => import('../views/About.vue') },
      { path: '/documents',name:"documents",  component: () => import('../views/ListeDocuments.vue') },
     { path: '/formationanalyse',name:"formationanalyse", component: () => import('../views/FormationsAnalyse.vue') },
      { path: '/cements',name:"cements", component: () => import('../views/ListeCements.vue') },
      { path: '/corrosions',name:"corrosions", component: () => import('../views/ListeCorrosions.vue') },
      //{ path: '/references', component: () => import('../views/ListeReferences.vue') },
     
      { path: '*', component: () => import('../components/NotFound.vue') },
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
