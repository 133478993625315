

import Vue from 'vue'
import VueApollo from 'vue-apollo'
import decode from 'jwt-decode'
import { InMemoryCache } from 'apollo-cache-inmemory';
import { createApolloClient, restartWebsockets } from 'vue-cli-plugin-apollo/graphql-client'
import { onError } from "apollo-link-error";
import { httpEndpoint } from "print/data.js";
import { ApolloLink } from 'apollo-link';
import router from "./router";

//import { from } from 'apollo-link'
//import { HttpLink } from 'apollo-link-http'
//import { onError } from 'apollo-link-error'



// Install the vue plugin
Vue.use(VueApollo)

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token'
const CACHE_VUEX = 'vuex'
const NET_ERROR = 'net-error'

//Sets a global error handler using the Apollo Error Link
const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)

    if (graphQLErrors[0]) {

      if (graphQLErrors[0].extensions.category === 'authentication') {       
        if (router.currentRoute.path!="/") router.push("/")
      }
    }

  if (networkError) {
    console.log(`[Network error]: ${networkError}`)
    sessionStorage.setItem(NET_ERROR, true)
  }
})
//Combines the Apollo Http and Error links
const link = ApolloLink.from([
  errorLink,
]);
//Sets the headers using Apollo Http Link
/*
const getHeaders = () => {
  const headers = {}
  const token = sessionStorage.getItem(AUTH_TOKEN) // comes from auth plugin, may also derived from session storage
  if (token) {
     headers.Authorization = `Bearer ${token}`
  }
  headers.accept = 'application/json'
  return headers
}
*/
// Http endpoint


// Config
const defaultOptions = {
  // You can use `https` for secure connection (recommended in production)
  httpEndpoint,
  // You can use `wss` for secure connection (recommended in production)
  // Use `null` to disable subscriptions
  wsEndpoint: null,
  // LocalStorage token
  tokenName: AUTH_TOKEN,
  // Enable Automatic Query persisting with Apollo Engine
  persisting: false,
  // Use websockets for everything (no HTTP)
  // You need to pass a `wsEndpoint` for this to work
  websocketsOnly: false,
  // Is being rendered on the server?
  ssr: false,

  // Override default apollo link
  // note: don't override httpLink here, specify httpLink options in the
  // httpLinkOptions property of defaultOptions.
  link: link,
 /* link: from([
    onError(errorHandler => {
       console.warn(errorHandler)
    }),
    new HttpLink({
       uri: httpEndpoint,
       headers: getHeaders()
    })
 ]),*/
  // Override default cache
  cache: new InMemoryCache(),

  // Override the way the Authorization header is set
  // getAuth: (tokenName) => ...
 
  // Additional ApolloClient options
  // apollo: { ... }

  // Client local data (see apollo-link-state)
  // clientState: { resolvers: { ... }, defaults: { ... } }
}


// Call this in the Vue app file
export function createProvider (options = {}) {
  // Create apollo client
  const { apolloClient, wsClient } = createApolloClient({
    ...defaultOptions,
    ...options,
  })
  apolloClient.wsClient = wsClient

  // Create vue apollo provider
  const apolloProvider = new VueApollo({
    defaultClient: apolloClient,
    defaultOptions: {
      $query: {
        fetchPolicy: 'cache-and-network',
        notifyOnNetworkStatusChange: true
      },

    },
    errorHandler (error) {
      // eslint-disable-next-line no-console
      console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
    },
  })

  return apolloProvider
}

//===============================


export function getAuthToken () {
  return sessionStorage.getItem(AUTH_TOKEN)
}

export function isLoggedIn () {
  let authToken = getAuthToken()
  return !!authToken && !isTokenExpired(authToken)
}
export function getexpiredate () {
  return getTokenExpirationDate(sessionStorage.getItem(AUTH_TOKEN))
}
export function getUserInfo () {
  if (isLoggedIn()) {
    return decode(getAuthToken())
  }
}

function getTokenExpirationDate (encodedToken) {
  let token = decode(encodedToken)
  if (!token.exp) {
    return null
  }

  let date = new Date(0)
  date.setUTCSeconds(token.exp)

  return date
}

function isTokenExpired (token) {
  let expirationDate = getTokenExpirationDate(token) 
  return expirationDate < new Date()
}

//===============================
// Manually call this when user log in
export async function onLogin (apolloClient, token) {
  if (token) {
    sessionStorage.setItem(AUTH_TOKEN, token)
    localStorage.setItem(AUTH_TOKEN, token)  
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (login)', 'color: orange;', e.message)
  }
}

// Manually call this when user log out
export async function onLogout (apolloClient) {
  if (typeof sessionStorage !== 'undefined') {
    
    sessionStorage.removeItem(AUTH_TOKEN)
    localStorage.removeItem(AUTH_TOKEN)
    sessionStorage.removeItem(CACHE_VUEX)
    //window.location.reload();
  }
  if (apolloClient.wsClient) restartWebsockets(apolloClient.wsClient)
  try {
    await apolloClient.resetStore()
  } catch (e) {
    // eslint-disable-next-line no-console
    console.log('%cError on cache reset (logout)', 'color: orange;', e.message)
  }
}
